import { useState, useEffect } from "react"

function useRestrictCharacterLength(maxCharactersAllowed = 300) {
  const [reachedMaxLength, setReachedMaxLength] = useState(false)

  const check = value => {
    console.log({ value })
    if (value > maxCharactersAllowed) {
      setReachedMaxLength(true)
      return true
    } else {
      setReachedMaxLength(false)
      return false
    }
  }

  return [reachedMaxLength, maxCharactersAllowed, check]
}

export default useRestrictCharacterLength
