import React from "react"
import errorImage from "./../../images/error.png"
class ShowError extends React.Component {
  render() {
    return (
      <div
        style={{
          width: "70%",
          marginLeft: "auto",
          marginRight: "auto",
          maxWidth: "300px",
          textAlign: "center",
        }}
      >
        <img src={errorImage} alt="" />
        <h3
          align=""
          style={{
            fontSize: "30px",
            // fontFamily: "Luckiest Guy",
            color: "#fec600",
            marginTop: "15px",
            fontWeight: "500",
          }}
          // className="font-lato-500"
        >
          Uh Oh!
        </h3>
        <h5
          align=""
          style={{ fontSize: "20px" }}
          className="subtitle is-3 font-lato-500"
        >
          Something went wrong here.
        </h5>
        <h5
          align=""
          style={{ fontSize: "16px" }}
          className="subtitle is-3 font-lato-400"
        >
          {/* We're working on it. */}
        </h5>
        {/* <p>
          <strong>Oh no!</strong>
        </p> */}
        {/* <p>Something went wrong, Please try again.</p> */}
        {/* <p>We're working on it. You can go back.</p> */}

        <button
          onClick={() => {
            window.history.back()
          }}
          className="button is-warning"
        >
          Go back
        </button>
      </div>
    )
  }
}

export default ShowError
