import React, { useEffect, useState } from "react"
import "./ShowLoading.scss"
import ShowError from "../Error/ShowError"

export default function ShowLoading({ timeOutValue = 20000 }) {
  //const timeOutValue = 7000
  const [showError, setShowError] = useState(false)
  useEffect(() => {
    console.log({ timeOutValue })
    const timer = setTimeout(() => {
      setShowError(true)
    }, timeOutValue)
    return () => {
      clearTimeout(timer)
    }
  }, [])

  return (
    <>
      {showError ? (
        <ShowError />
      ) : (
        <>
          <div
            className="sk-cube-grid absolute-center"
            // style={{ width: "100px" }}
          >
            <div className="sk-cube sk-cube1"></div>
            <div className="sk-cube sk-cube2"></div>
            <div className="sk-cube sk-cube3"></div>
            <div className="sk-cube sk-cube4"></div>
            <div className="sk-cube sk-cube5"></div>
            <div className="sk-cube sk-cube6"></div>
            <div className="sk-cube sk-cube7"></div>
            <div className="sk-cube sk-cube8"></div>
            <div className="sk-cube sk-cube9"></div>
          </div>
        </>
      )}
    </>
  )
}
