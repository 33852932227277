import React, { Fragment } from "react"
import Layout from "../components/Layout"
import SEO from "../components/seo"
import { useLocation } from "@reach/router"
import { parse } from "query-string"
import { navigate } from "gatsby"
import ReferralProducer from "../components/Referrals/ReferralProducer"
const InviteHOC = ({ rId }) => {
  const location = useLocation()

  const getType = () => {
    try {
      const searchParams = parse(location.search)
      console.log({ searchParams })
      if (searchParams.type) {
        return searchParams.type
      } else return "newsletter"
    } catch (error) {}
  }
  if (typeof window !== "undefined") {
    const authUser = localStorage.getItem("authUser")
    // const canonicalUrl = data.site.siteMetadata.siteURL + location.pathname
    console.log(location.pathname)
    if (!authUser && location.pathname !== `/app/login`) {
      // If we’re not logged in, redirect to the home page.
      localStorage.setItem(
        "redirectAfterSignIn",
        JSON.stringify({
          value: true,
          url: `${location.pathname}${location.search}`,
        })
      )
      navigate(`/signup`)
      return null
    } else {
      localStorage.setItem(
        "redirectAfterSignIn",
        JSON.stringify({ value: false, url: `` })
      )
    }
  }

  return (
    <Fragment>
      {/* <SignUpForm type={"traveler"} /> */}
      <ReferralProducer iType={getType()} />
    </Fragment>
  )
}

export default () => (
  <Layout>
    <SEO
      pageTitle="Referral Rewards"
      description="Each friend that signs up will give you a chance to earn more!"
    />
    <InviteHOC />
  </Layout>
)
